function turboCSP() {
  // https://github.com/hotwired/turbo/issues/294#issuecomment-996585109
  document.addEventListener("turbo:before-fetch-request", (event) => {
    event.detail.fetchOptions.headers["X-Turbo-Nonce"] =
      document.querySelector("meta[name='csp-nonce']")?.content
  })

  document.addEventListener("turbo:before-cache", () => {
    document.querySelectorAll("script[nonce], link[nonce]").forEach((element) => {
      element.setAttribute("nonce", element.nonce);
    })
  })
}

function initTurbo() {
  if (rack_env === 'development') {
    // local development では、Turbo の progress bar の表示の閾値を下げる
    Turbo.setProgressBarDelay(10)
  }
  turboCSP();
}

document.addEventListener("DOMContentLoaded", initTurbo);
