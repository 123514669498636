function turbolinksEvent() {
  // iOS app は、turbolinks:load の発火を期待するので turbo:load で発火させる
  document.addEventListener("turbo:load", () => {
    const event = new CustomEvent("turbolinks:load", { bubbles: true })
    document.documentElement.dispatchEvent(event)
  });
}
function shim() {
  if (window.navigator.userAgent.match(/pplog-client-ios/)) {
    turbolinksEvent();
  }
}

document.addEventListener("DOMContentLoaded", shim);
